import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const Reviews = () => {
	const [reviews, setReviews] = useState([]);

	useEffect(() => {
		axios
			.get(
				`https://www.local-marketing-reports.com/external/showcase-reviews/widgets/`
			)
			.then((res) => {
				const reviewData = res.data.results;
				setReviews(reviewData);
				setReviews(reviewData);
				document.getElementById("loading-spinner").style.display = "none";
				document.getElementById("no-reviews").style.display = "block";
			});
	});

	const data = useStaticQuery(
		graphql`
			query {
				googleImg: file(relativePath: { eq: "googlereview.png" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}

				facebookImg: file(relativePath: { eq: "facebook.png" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				site {
					siteMetadata {
						siteUrl
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const ogImg = "";

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Example",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Reviews",
				item: {
					url: `${siteUrl}/reviews`,
					id: `${siteUrl}/reviews`,
				},
			},
		],
	};

	return (
		<main>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Example Website"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Example Website",
					description: "Example Website",
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Jason Cornes Business Coach Reviews",
						},
					],
				}}
			/>
			<Container>
				<Row>
					<Col>
						<Row>
							<Col xs={12}>
								<p className="text-center" style={{ display: "none" }}>
									<em>Rated 4.9 / 5 across {reviews.length} Reviews</em>
								</p>
							</Col>
							<Col className="d-flex justify-content-center" xs={12}>
								<span className="d-block" />
							</Col>
						</Row>

						<Row className="justify-content-center">
							<Spinner
								id="loading-spinner"
								variant="primary"
								animation="border"
							>
								<span className="sr-only">Loading...</span>
							</Spinner>
							{/* {reviews.map(review => 
                  (      
                    <Col xs={11} sm={10} xl={6}>
                    <Row className="align-items-center">
                    <Col xs={12} xl={2} className="review-image-container">
                      {review.source === "google" && 
                      <Img fluid={googleImg} className="review-image" alt="5 star review of local locksmith"/>
                      }
                      {review.source === "facebook" && 
                      <Img fluid={facebookImg} className="review-image" alt="5 star review of local locksmith"/>
                      }
                      {review.source === "yell" && 
                      <Img fluid={yellowPagesImg} className="review-image" alt="5 star review of local locksmith"/>
                      }
                    </Col>
                    <Col xs={12} xl={10} className="review-text">
                    <p className="lato fw-700 text-lg">{review.datePublished}</p>
                      <p className="fw-500 italic text-lg">
                        <span className="text-orange">&ldquo;</span>{review.reviewBody}<span className="text-orange">&rdquo;</span>
                      </p>
                      <p className="lato fw-700 text-lg">
                        <span className="text-orange">-</span> {review.author}
                      </p>
                      
                    </Col>
                  </Row>
                  </Col>
                  ))} */}
						</Row>
					</Col>
				</Row>
			</Container>
		</main>
	);
};

export default Reviews;
